import styled from 'styled-components';
import theme from '../data/theme';

export const TextSection = styled.section`
  max-width: ${ theme.maxTextWidth };
  min-width: 30rem;
  margin: 0 auto;
`;

export const SectionHeading = styled.h2`
  font-size: 2.4rem;
  font-weight: 600;
`;

export const SubHeading = styled.h3`
  font-size: 2rem;
  font-weight: 600;
  margin-top: 7rem;
`;

export const DL = styled.dl`
  padding-left: 2rem;
  margin-top: 5rem;
`;

export const DT = styled.dt`
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
`;

export const DD = styled.dd`
  font-size: 1.6rem;
  margin-bottom: 5rem;
  line-height: 1.5;
`;

export const UL = styled.ul`
  padding-left: 4rem;
  margin: 2.5rem 0;
`;

export const LI = styled.li`
  font-size: 1.8rem;
  line-height: 1.5;
`;

export const Declaration = styled.p`
  font-size: 2.0rem;
  line-height: 1.5;
  margin-bottom: 2.5rem;
`;

export const SectionText = styled.p`
  font-size: 1.6rem;
  line-height: 1.5;
`;

export const Note = styled.p`
  font-size: 1.4rem;
  margin-bottom: 5rem;
`;

export const Exclamation = styled.p`
  font-size: 2.4rem;
  font-weight: 600;
`;
