import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
// import ReCAPTCHA from 'react-google-recaptcha';
import { FormField, FormLabel, InputValidate, CheckboxWrapper, Checkbox, CheckboxLabel, FormTextArea, SubmitButton, TextInput } from './forms';
import { isNotEmpty, isEmail, hasFiveNums } from './../utils/validation';

// const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY;

const Form = styled.form`
`;

const ServiceSelection = styled.div`
  width: 100%;
  max-width: 100%;
  column-width: 17em;
  column-count: auto;
  column-fill: balance;
  column-gap: 0.5rem;
  margin-bottom: 2.5rem;
`;

const ServiceSelectionLabel = styled(FormLabel)`
  margin-bottom: 0.5rem;
`;

const servicesOptions = [
  'Mowing',
  'Edging',
  'Weed Care',
  'Mulching',
  'Trimming and Pruning',
  'Spring Cleanup',
  'Leaf Removal',
  'Annual Planting',
  'Annual Removal',
  'Snow Removal',
  'Erosion Control',
  'Fertilizing',
];

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export default class QuoteForm extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      // 'g-recaptcha-response': '',
      kids: [],
    };

    this.register = this.register.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // handleRecaptcha = value => {
  //   this.setState({ 'g-recaptcha-response': value });
  // };

  handleSubmit = e => {
    let valid = this.state.kids.reduce((acc, component) => {
      const error = component.validate(component.state.value);
      component.setState({
        touched: true,
        error: error,
      });
      return acc && (error === undefined);
    }, true);

    // valid = valid && this.state['g-recaptcha-response'].length > 0;

    console.log(valid);

    if (!valid) {
      console.log('PD')
      e.preventDefault();
    }
  }

  register = component => {
    this.state.kids.push(component);
  }

  render () {
    return <Form
      name="quoterequest"
      method="post"
      action="/quote/thank-you/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={this.handleSubmit}
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="quoterequest" />
      <FormField>
        <FormLabel htmlFor="name">NAME</FormLabel>
        <InputValidate component={TextInput} id="name" name="name" type="text" validate={[isNotEmpty]} register={this.register}></InputValidate>
      </FormField>
      <FormField>
        <FormLabel htmlFor="email">EMAIL ADDRESS</FormLabel>
        <InputValidate component={TextInput} id="email" name="email" type="text" validate={[isNotEmpty, isEmail]} register={this.register}></InputValidate>
      </FormField>
      <FormField>
        <FormLabel htmlFor="phone">PHONE NUMBER (optional)</FormLabel>
        <InputValidate component={TextInput} id="phone" name="phone" type="tel" register={this.register}></InputValidate>
      </FormField>
      <FormField>
        <FormLabel htmlFor="zip">ZIP CODE</FormLabel>
        <InputValidate component={TextInput} id="zip" name="zipcode" type="text" validate={[isNotEmpty, hasFiveNums]} register={this.register}></InputValidate>
      </FormField>
      <FormField>
        <FormLabel htmlFor="street">STREET ADDRESS (optional)</FormLabel>
        <InputValidate component={TextInput} id="street" name="address" type="text" register={this.register}></InputValidate>
      </FormField>
      <FormField>
        <ServiceSelectionLabel>SERVICES (SELECT ALL THAT APPLY)</ServiceSelectionLabel>
        <ServiceSelection>
          {servicesOptions.map((option, ix) => {
            return (<CheckboxWrapper key={ix}>
              <Checkbox id={option.toLowerCase().replace(/ /g, '')} name={`needs-${ option.toLowerCase().replace(/ /g, '-') }`} type="checkbox"/>
              <CheckboxLabel htmlFor={option.toLowerCase().replace(/ /g, '')}>{option}</CheckboxLabel>
            </CheckboxWrapper>);
          })}
        </ServiceSelection>
      </FormField>
      <FormField>
        <FormLabel htmlFor="comments">DESCRIPTION / COMMENTS</FormLabel>
        <FormTextArea id="comments" name="comments" rows="7"></FormTextArea>
      </FormField>
      {/* <ReCAPTCHA sitekey={RECAPTCHA_KEY} onChange={this.handleRecaptcha} /> */}
      <SubmitButton type="submit">Submit</SubmitButton>
    </Form>;
  }
}
