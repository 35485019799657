import React from 'react';
import Layout from './layout';
import SEO from './seo';
import PageHeading from './PageHeading';

import styled, { css } from 'styled-components';
import theme from '../data/theme';

import QuoteForm from './QuoteForm';
import { whenTablet } from '../utils/responsive';

import { SectionHeading, Note } from './textComponents';

const MainWrapper = styled.div`
  width: ${ theme.contentWidth };
  max-width: ${ theme.maxContentWidth };
  margin: 5rem auto;
`;

export default ({ page, heading, children }) => (
  <Layout>
    <SEO title={page.title} keywords={page.keywords} description={page.description} />
    <MainWrapper>
      {children}
    </MainWrapper>
  </Layout>
);
