import React from 'react';
import styled from 'styled-components';
// import ReCAPTCHA from 'react-google-recaptcha';
import { FormField, FormLabel, InputValidate, FormTextArea, SubmitButton, TextInput } from './../../components/forms';
import { isNotEmpty, isEmail, isUSPhone } from './../../utils/validation';
import { SectionHeading, Note } from '../../components/textComponents';

import { FaPaperclip } from 'react-icons/fa';

import LightPage from './../../components/LightPage';

// const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY;

const keywords = [
  'VLP',
  'Landscaping',
  'Landscaper',
  'Matt Vojticek',
  'Northeast Ohio',
];

const page = {
  title: 'Job Application',
  keywords: keywords,
};

const heading = {
  title: page.title,
};

const FileUploadWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

const FileUploadButton = styled.button`
  width: 25rem;
  font-size: 1.6rem;
  line-height: 1;
  padding: 1rem 1rem;
  font-weight: 600;
  border: 0;
  margin: 0;
  cursor: pointer;
  text-align: left;
`;

const FileUploadHiddenInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 25rem;
  height: 100%;
`;

const PlaceholderText = styled.span`
  color: hsla(0, 0%, 0%, 0.5);
  margin-left: 1rem;
`;

const FilledText = styled.span`
  margin-left: 1rem;
`;

class ApplyForm extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      // 'g-recaptcha-response': '',
      kids: [],
      resumeFilename: undefined,
    };

    this.register = this.register.bind(this);
    this.changeResume = this.changeResume.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // handleRecaptcha = value => {
  //   this.setState({ 'g-recaptcha-response': value });
  // };

  handleSubmit = e => {
    let valid = this.state.kids.reduce((acc, component) => {
      const error = component.validate(component.state.value);
      component.setState({
        touched: true,
        error: error,
      });
      return acc && (error === undefined);
    }, true);

    // valid = valid && this.state['g-recaptcha-response'].length > 0;

    if (!valid) {
      e.preventDefault();
    }
  }

  register = component => {
    this.state.kids.push(component);
  }

  changeResume = event => {
    const filename = event.target.value.split(/(\\|\/)/g).pop();
    this.setState({
      resumeFilename: filename,
    });
  }

  changeApplication = event => {
    const filename = event.target.value.split(/(\\|\/)/g).pop();
    this.setState({
      applicationFilename: filename,
    });
  }

  render () {
    return <form
      name="jobapplication"
      method="post"
      action="/careers/thank-you/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={this.handleSubmit}
    >
      <input type="hidden" name="bot-field"></input>
      <input type="hidden" name="form-name" value="jobapplication" />
      <FormField>
        <FormLabel htmlFor="name">NAME</FormLabel>
        <InputValidate
          component={TextInput}
          id="name"
          name="name"
          type="text"
          validate={[isNotEmpty]}
          register={this.register}/>
      </FormField>
      <FormField>
        <FormLabel htmlFor="email">EMAIL ADDRESS</FormLabel>
        <InputValidate
          component={TextInput}
          id="email"
          name="email"
          type="text"
          validate={[isNotEmpty, isEmail]}
          register={this.register}/>
      </FormField>
      <FormField>
        <FormLabel htmlFor="phone">PHONE NUMBER</FormLabel>
        <InputValidate
          component={TextInput}
          id="phone"
          name="phone"
          type="tel"
          validate={[isNotEmpty, isUSPhone]}
          register={this.register}/>
      </FormField>
      <FormField>
        <FormLabel htmlFor="applicationupload">APPLICATION</FormLabel>
        <FileUploadWrapper>
          <FileUploadButton>
            <FaPaperclip/>
            {!this.state.applicationFilename && <PlaceholderText>Attach Application</PlaceholderText>}
            {this.state.applicationFilename && <FilledText>{this.state.applicationFilename}</FilledText>}
          </FileUploadButton>
          <InputValidate
            component={FileUploadHiddenInput}
            id="applicationupload"
            name="applicationupload"
            type="file"
            onChange={this.changeApplication}
            accept="image/*,application/msword,application/pdf,text/plain"
            validate={[isNotEmpty]}
            register={this.register}/>
        </FileUploadWrapper>
      </FormField>
      <FormField>
        <FormLabel htmlFor="resumeupload">RESUM&Eacute; (optional)</FormLabel>
        <FileUploadWrapper>
          <FileUploadButton>
            <FaPaperclip/>
            {!this.state.resumeFilename && <PlaceholderText>Attach Resum&eacute;</PlaceholderText>}
            {this.state.resumeFilename && <FilledText>{this.state.resumeFilename}</FilledText>}
          </FileUploadButton>
          <InputValidate
            component={FileUploadHiddenInput}
            id="resumeupload"
            name="resumeupload"
            type="file"
            onChange={this.changeResume}
            accept="image/*,application/msword,application/pdf,text/plain"
            validate={[]}
            register={this.register}/>
        </FileUploadWrapper>
      </FormField>
      <FormField>
        <FormLabel htmlFor="comments">ADDITIONAL INFORMATION (optional)</FormLabel>
        <FormTextArea id="comments" name="comments" rows="7"></FormTextArea>
      </FormField>
      {/* <ReCAPTCHA sitekey={RECAPTCHA_KEY} onChange={this.handleRecaptcha} /> */}
      <SubmitButton type="submit">Submit</SubmitButton>
    </form>;
  }
}

const ApplyFormWrapper = styled.div`
  max-width: 40rem;
  min-width: 30rem;
  margin: 5rem auto;
`;

export default () => (
  <LightPage page={page} heading={heading}>
    <ApplyFormWrapper>
      <SectionHeading>Submit Job Application</SectionHeading>
      <Note>Just fill this out, and we will contact you to discuss further.</Note>
      <ApplyForm/>
    </ApplyFormWrapper>
  </LightPage>
);