import React from 'react';
import styled from 'styled-components';
import theme from './../data/theme';

export const FormField = styled.div`
  margin-bottom: 2.5rem;
`;

export const FormLabel = styled.label`
  display: block;
  color: darkgray;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0rem;
`;

export const CheckboxWrapper = styled.div`
  margin: 0.5rem 0;
`;

export const Checkbox = styled.input`
  height: 2rem;
  width: 2rem;
  margin-right: 0.5rem;
  background-color: lightgray;
  display: inline-block;
  vertical-align: middle;

  :hover {
    background-color: darkgray;
  }
`;

export const CheckboxLabel = styled.label`
  font-size: 1.2rem;
  font-weight: 400;
  display: inline-block;
  vertical-align: middle;
`;

export const TextInput = styled.input`
  width: 100%;
  max-width: 30rem;
  border: 0;
  border-bottom: ${ props => props.invalid ? '2px solid hsla(0, 100%, 50%, 0.2)' : '2px solid lightgray' };
  padding: 0.5rem;
  font-family: inherit;
  font-size: 1.8rem;
  background-color: inherit;

  &:focus {
    outline: none;
    border-bottom: ${ props => props.invalid ? '2px solid red' : '2px solid ' + theme.colors.primary };
  }
`;

export const FormTextArea = styled.textarea`
  border: 2px solid lightgray;
  width: 100%;
  max-width: 40rem;
  font-size: 1.5rem;
  font-family: inherit;
  line-height: 1.4;
  padding: 0.5rem;

  &:focus {
    outline: none;
    border: ${ '2px solid ' + theme.colors.primary };
  }
`;

const FormInputError = styled.span`
  display: block;
  color: red;
  font-size: 1.2rem;
  line-height: 1.2rem;
  height: 1.2rem;
  margin-top: 0.2rem;
`;

export const SubmitButton = styled.button`
  background-color: ${ theme.colors.primary };
  color: white;
  font-size: 1.8rem;
  padding: 1rem 3rem;
  font-weight: 600;
  border: 0;
  margin: 3rem 0;
  cursor: pointer;

  &:hover {
    background-color: ${ theme.colors.darkPrimary };
  }

  &:focus {
    background-color: ${ theme.colors.darkPrimary };
  }

  &:active {
    background-color: ${ theme.colors.black };
  }

  outline: none;
`;

export class InputValidate extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      value: '',
      error: '',
      touched: false,
    };

    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.validate = this.validate.bind(this);

    this.props.register(this);
  }

  handleOnBlur (event) {
    const error = this.validate(event.target.value);
    this.setState({
      touched: true,
      error: error,
    });
  }

  handleOnChange (event) {
    this.props.onChange && this.props.onChange(event);
    const error = this.validate(event.target.value);
    this.setState({
      error: error,
      value: event.target.value,
    });
  }

  validate (value) {
    let error;
    if (this.props.validate) {
      error = this.props.validate.reduce((acc, func) => { return acc || func(value); }, undefined);
    };

    return error;
  }

  render () {
    return <>
      <this.props.component
        id={this.props.id}
        name={this.props.name}
        type={this.props.type}
        onBlur={this.handleOnBlur}
        onChange={this.handleOnChange}
        value={this.state.value}
        invalid={this.state.touched && this.state.error}
        accept={this.props.accept}
      >
      </this.props.component>
      <FormInputError>{
        this.state.touched &&
        this.state.error &&
        this.state.error.toUpperCase()
      }
      </FormInputError>
    </>;
  }
};
