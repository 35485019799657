import React from 'react';
import styled, { css } from 'styled-components';

import { whenTablet } from '../utils/responsive';

import theme from '../data/theme';

const headingHeight = '45rem';

const TitleCard = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 2.5rem;
  background-color: white;
  border-bottom: 10px solid ${ theme.colors.primary };
  box-shadow: 0px 2px 13px 0px hsla(0, 100%, 0%, 0.3);

  ${ whenTablet(css`
    position: absolute;
    z-index: 5;
    left: 0;
    top: 8.5rem;
    width: 30%;
    min-width: 35rem;
    padding: 3.5rem;
  `) }
`;

const Heading = styled.h1`
  font-size: 4rem;
  font-weight: 600;
  margin: 3rem 0 1rem;

  ${ whenTablet(css`
    font-size: 4rem;
  `) };
`;

const HeadingImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  max-height: 35rem;
  object-fit: cover;

  ${ whenTablet(css`
    position: absolute;
    right: 0;
    width: 65%;
    max-height: ${ headingHeight };
    height: ${ headingHeight };
    object-fit: cover;
  `) }
`;

const CardImageWrapper = styled.div`
  width: ${ theme.contentWidth };
  margin: 1rem auto;

  ${ whenTablet(css`
    max-width: ${ theme.maxContentWidth };
    height: ${ headingHeight };
    position: relative;
  `) }
`;

const PageHeading = ({ title, img, alt }) => (
  <CardImageWrapper>
    <TitleCard>
      <Heading>{title}</Heading>
    </TitleCard>
    <HeadingImage src={img} alt={alt}></HeadingImage>
  </CardImageWrapper>
);

// Basic Page Heading
// (No image)
const PageTitle = styled.h1`
  font-size: 4rem;
  font-weight: 600;
`;

const PageBar = styled.hr`
  width: 6rem;
  height: 0.5rem;
  background-color: ${ theme.colors.primary };
`;

const PageHeadingSleeve = styled.div`
  width: ${ theme.contentWidth };
  max-width: ${ theme.maxContentWidth };
  margin: 7rem auto 4rem;
`;

export const BasicPageHeading = ({ page }) => (
  <PageHeadingSleeve>
    <PageTitle>{page.title}</PageTitle>
    <PageBar />
  </PageHeadingSleeve>
);

export default PageHeading;
